<template>
  <div class="addM">
    <a-button class="button1" @click="showModal()"
      >新增<a-icon type="plus"
    /></a-button>
    <a-button style="font-size: large; margin-left: 20px;" @click="showUnitDialog()"
    >單位管理</a-button>
    <a-button style="font-size: large; margin-left: 20px;" @click="showCategoryDialog()"
    >商品分類管理</a-button>
    <a-modal
      v-model="visible"
      :title="changeTitle"
      width="1000px"
      @cancel="clearInput"
      id="modal-wrapper"
    >
      <div class="modal-body">
        <a-form-model :model="list" ref="ruleForm" :rules="rules">
          <div class="firstPart">
            <div class="firstPart-item">
              <div class="firstPart-item__row">
                <a-form-model-item class="custom-form-item"
                                   label="商品包裝"
                                   prop="categories">
                  <a-select v-model="list.categories"
                            style="z-index: 1"
                            @change="barCodeVerify">
                    <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">
                      {{ item.category }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <div class="product-category">
                  <a-form-model-item
                    class="flex-1-form-model-item custom-form-item"
                    label="商品分類"
                    prop="categoryId"
                  >
                      <a-select
                        v-model="list.categoryId"
                        placeholder="請選擇商品分類"
                      >
                        <a-select-option v-for="e in productCategories" :value="e.id" :key="e.id">
                          {{ e.name }}
                        </a-select-option>
                      </a-select>
                  </a-form-model-item>
                  <a-button class="product-category__btn" type="primary" @click="showCategoryDialog()"><a-icon type="plus"/></a-button>
                </div>
              </div>
              <div class="firstPart-item__row">
                <a-form-model-item
                  class="custom-form-item"
                  label="商品名稱"
                  prop="name"
                >
                  <a-input
                    ref="inputDom"
                    v-model="list.name"
                    placeholder="請輸入"
                  />
                </a-form-model-item>
                <a-form-model-item
                  class="custom-form-item"
                  label="商品簡稱"
                >
                  <a-input v-model="list.alias" placeholder="請輸入" />
                </a-form-model-item>
              </div>
              <div class="firstPart-item__row">
                <a-form-model-item
                  class="custom-form-item"
                  label="建議售價"
                  prop="listPrice"
                >
                  <a-input
                    v-model="listPriceFormatting"
                    prefix="$"
                    placeholder="請輸入"
                  />
                </a-form-model-item>
                <a-form-model-item
                  class="custom-form-item"
                  label="單價"
                  prop="price"
                >
                  <a-input
                    v-model="priceFormatting"
                    prefix="$"
                    placeholder="請輸入"
                  />
                </a-form-model-item>
              </div>
              <div class="firstPart-item__row">
                <a-form-model-item
                  class="custom-form-item custom-form-item-center"
                  label="重量"
                >
                  <div class="firstPart-weight">
                    <a-input
                      class="firstPart-weight__input"
                      v-model="list.weight"
                      placeholder="請輸入"
                      v-mask="decimalFormat"
                    />
  <!--                    <threeWeights-->
  <!--                            v-model="list.weightUnit"-->
  <!--                            :weightUnitVerify="weightUnitVerify"-->
  <!--                    />-->
                    <a-select
                      class="firstPart-weight__select"
                      v-model="weightUnitId"
                      placeholder="請選擇"
                      @change="weightUnitSelectChange"
                    >
                      <a-select-option v-for="u in unitData" :value="u.id" :key="u.id">
                        {{ u.name }}
                      </a-select-option>
                    </a-select>
                  </div>
  <!--                <div v-else>-->
  <!--                  <a-input disabled style="width: 230px" />-->
  <!--                  <a-select style="width: 120px" disabled>-->
  <!--                    <a-select-option value="1">-->
  <!--                      ""-->
  <!--                    </a-select-option>-->
  <!--                  </a-select>-->
  <!--                </div>-->
                </a-form-model-item>
                <a-form-model-item
                  class="custom-form-item custom-form-item-center"
                  label="單位"
                  prop="unit"
                >
                  <div class="firstPart-unit">
                    <a-select
                      class="firstPart-unit__select"
                      v-model="unitId"
                      placeholder="請選擇"
                      @change="unitSelectChange"
                    >
                      <a-select-option v-for="u in unitData" :value="u.id" :key="u.id">
                        {{ u.name }}
                      </a-select-option>
                    </a-select>
                    <a-button class="firstPart-unit__btn" type="primary" @click="showUnitDialog()"><a-icon type="plus"/></a-button>
                  </div>
                </a-form-model-item>
              </div>
              <div class="firstPart-item__row">
                <a-form-model-item
                  class="custom-form-item"
                  label="商品條碼"
                  prop="barcode"
                >
                  <a-input v-if="list.categories === 'RETAIL'" v-model="list.barcode" placeholder="請輸入" />
                  <a-input v-else disabled />
                </a-form-model-item>
                <a-form-model-item
                  class="custom-form-item"
                  label="預設標籤"
                  prop="tagId"
                >
                  <a-select
                    v-model="list.tagId"
                    @change="passTagId"
                  >
                    <a-select-option v-for="item in tagList" :key="item.id">
                      {{ item.tagName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </div>
            </div>
            <div class="firstPart-item__row firstPart-item__row-full">
              <a-form-model-item class="desc-area custom-form-item" label="商品描述">
                <a-textarea
                  class="aaa"
                  v-model="list.description"
                  placeholder="請輸入"
                  style="height: 100px;"
                />
              </a-form-model-item>
            </div>
          </div>
        </a-form-model>
      </div>

      <div>
        出貨單價設定
        <a-button
          type="primary"
          size="small"
          class="editable-add-btn"
          @click="handleAdd"
        >
          <a-icon type="plus" />
        </a-button>
        <template>
          <a-table
            class="salesTable"
            bordered
            :data-source="salesTable"
            :columns="columns"
            :rowKey="record => record.id"
            :pagination="{
              newCurrent,
              newPageSize,
              newPageSizeOptions
            }"
            @change="salesTableChange"
          >
          </a-table>
        </template>
      </div>

      <template slot="footer">
        <div v-if="list.updateTime">
          <span
            >上次更新時間: {{ list.updateTime.split(' ')[0]
            }}<span style="display: inline-block; width: 10px;" />
            {{ list.updateTime.split(' ')[1] }}</span
          >
        </div>
        <a-button key="back" @click="handleCancel">
          取消
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
        >
          儲存
        </a-button>
        <a-button
          v-show="changeTitle === '新增商品'"
          type="primary"
          :loading="loading"
          @click="submitNonstop"
        >
          儲存並新增
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="unitDialogVisible"
      width="600px"
      :footer="null"
      @cancel="unitDialogCancel"
    >
      <template slot="title">
        <div>
          <span>單位管理</span>
          <a-button :disabled="isEdit" type="primary" style="margin-left: 10px;" @click="addUnitRow"><a-icon type="plus"
          /></a-button>
        </div>
      </template>
      <div>
        <a-table
                :columns="unitListColumns"
                :data-source="unitData"
                :pagination="false"
                :scroll="{ y: 500 }"
        >
          <template slot="name" slot-scope="text,record">
            <div v-if="record.add"><a-input v-model="unitName"></a-input></div>
            <div v-else><a-input v-if="record.isEdited" v-model="unitName"></a-input><span v-else>{{ text }}</span></div>
          </template>
          <template slot="action" slot-scope="text,record">
            <div v-if="record.edit">
              <div v-if="!record.add">
                <div v-if="!record.isEdited">
                <a-button :disabled="isAddUnit" @click="editUnitRow(record)">編輯</a-button>
                  <a-popconfirm
                          title="確定要刪除嗎?"
                          @confirm="deleteUnit(record)"
                  >
                  <a-button :disabled="isAddUnit">刪除</a-button>
                  </a-popconfirm>
                </div>
                <div v-else><a-button @click="cancelEdit(record)">取消</a-button><a-button type="primary" @click="editUnit(record)">儲存</a-button></div>
              </div>
              <div v-else>
                <a-button @click="cancelAdd">取消</a-button><a-button type="primary" @click="addUnit">儲存</a-button>
              </div>
            </div>
            <div v-else>--</div>
          </template>
        </a-table>
      </div>
    </a-modal>
    <a-modal
      v-model="categoriesDialogVisible"
      width="600px"
      :footer="null"
      @cancel="categoriesDialogCancel"
    >
      <template slot="title">
        <div>
          <span>商品分類管理</span>
          <a-button :disabled="isEdit" type="primary" style="margin-left: 10px;" @click="addCategoryRow"><a-icon type="plus"
          /></a-button>
        </div>
      </template>
      <div>
        <a-table
          :columns="categoriesColumns"
          :data-source="categoriesData"
          :pagination="false"
          :scroll="{ y: 500 }"
        >
          <template slot="name" slot-scope="text,record">
            <div v-if="record.add"><a-input v-model="categoryName"></a-input></div>
            <div v-else><a-input v-if="record.isEdited" v-model="categoryName"></a-input><span v-else>{{ text }}</span></div>
          </template>
          <template slot="action" slot-scope="text,record">
            <div v-if="record.edit">
              <div v-if="!record.add">
                <div v-if="!record.isEdited">
                  <a-button :disabled="isAddCategory" @click="editCategoryRow(record)">編輯</a-button>
                  <a-popconfirm
                    title="確定要刪除嗎?"
                    @confirm="deleteCategory(record)"
                  >
                    <a-button :disabled="isAddCategory">刪除</a-button>
                  </a-popconfirm>
                </div>
                <div v-else><a-button @click="cancelEditCategory(record)">取消</a-button><a-button type="primary" @click="editCategory(record)">儲存</a-button></div>
              </div>
              <div v-else>
                <a-button @click="cancelAddCategory">取消</a-button><a-button type="primary" @click="addCategory">儲存</a-button>
              </div>
            </div>
            <div v-else>--</div>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>
<script>
import formatPrice from '@/components/thousand'
import translate from '@/components/translate'
import threeWeights from '@/components/threeweightTrans'
import Fragment from '@/components/Fragment.vue'
// import { computedWeight } from "@/unit/dictionary/computed";

const initialList = {
  name: '',
  unit: 'KG',
  categoryId: undefined,
  unitType: '',
  barcode: '',
  listPrice: '',
  price: '',
  description: '',
  using: undefined,
  updateTime: '',
  tagId: '',
  categories:'',
  alias: '',
  weight: 0,
  weightUnit: ''
}

export default {
  name: 'CustomPrice',
  components: { translate, threeWeights },
  props: ['getCommodity', 'tableData', 'productCategories', 'getProductCategories'],
  data() {
    return {
      newCurrent: 1,
      newPageSize: 10,
      newPageSizeOptions: ['10', '30', '50', '100'],
      defaultTagId: '',
      selectedId: [],
      cusList: [],
      customerId: '',
      customerList: [],
      tagList: [],
      salesTable: [],
      loading: false,
      visible: false,
      changeTitle: '',
      unitDialogVisible: false,
      isAddUnit: false,
      isEdit: false,
      unitId: '',
      weightUnitId: '',
      unitName: '',
      weightUnitName: '',
      unitData: [],
      unitListColumns: [
        {
          title: '單位',
          dataIndex: 'name',
          align: 'center',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '操作',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      categoriesDialogVisible: false,
      categoriesColumns: [
        {
          title: '商品分類',
          dataIndex: 'name',
          align: 'center',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '操作',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      categoriesData: [],
      categoryName: '',
      isAddCategory: false,
      categoryList:[{id:'COMMERCIAL',category: '商用包'}, {id:'RETAIL',category: '零售包'}],
      classesList: [],
      list: {...initialList},
      rules: {
        barcode: [
          {
            required: true,
            pattern: /^\d+$/,
            message: '請輸入數字',
            trigger: 'blur'
          }
        ],
        unit: [{ required: true, message: '請選擇', trigger: 'blur' }],
        categoryId: [{ required: true, message: '請選擇商品分類', trigger: 'blur' }],
        weightUnit: [{ required: false, message: '請選擇', trigger: 'blur' }],
        tagId: [{ required: true, message: '請選擇', trigger: 'blur' }],
        name: [{ required: true, message: '請輸入商品名稱', trigger: 'blur' }],
        listPrice: [
          {
            required: true,
            pattern: /^\d+$/,
            message: '請輸入數字',
            trigger: 'blur'
          }
        ],
        price: [
          {
            required: true,
            pattern: /^\d+$/,
            message: '請輸入數字',
            trigger: 'blur'
          }
        ],
        categories: [
          {
            required: true,
            message: '請選擇',
            trigger: 'blur'
          }
        ],

      },
      columns: [
        {
          title: '客戶類別',
          dataIndex: 'classes',
          align: 'center',
          customRender: (val, row) => {
            return {
              children: (
                <div>
                  <a-select
                    style="width:200px"
                    placeholder="請選擇"
                    onChange={id => this.handleChange(id, row)}
                    vModel={row.classes}
                  >
                    {this.classesList.map(item => (
                      <a-select-option value={item.id}>
                        {item.className}
                      </a-select-option>
                    ))}
                  </a-select>
                </div>
              )
            }
          },
          scopedSlots: { customRender: 'classes' }
        },
        {
          title: '客戶名稱',
          dataIndex: 'clientName',
          align: 'center',
          customRender: (val, row) => {
            return {
              children: (
                <div>
                  <a-select
                    style="width:200px"
                    vModel={row.clientName}
                    placeholder="請選擇"
                    onChange={id => this.clientOption(id, row)}
                  >
                    {row.storeClient.map(item => (
                      <a-select-option value={item.id} disabled={item.disabled}>
                        {item.name}
                      </a-select-option>
                    ))}
                  </a-select>
                </div>
              )
            }
          },
          scopedSlots: { customRender: 'clientName' }
        },
        {
          title: '出貨單價',
          dataIndex: 'discountPrice',
          align: 'center',
          width: 100,
          customRender: (val, row) => {
            return this.priceAndRemarkEditor(val, row, 'discountPrice')
          },
          scopedSlots: { customRender: 'discountPrice' }
        },
        {
          title: '備註',
          dataIndex: 'remark',
          align: 'center',
          width: 200,
          customRender: (val, row) => {
            return this.priceAndRemarkEditor(val, row, 'remark')
          },
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          customRender: (value, row, index) => ({
            children: (
              <div>
                {this.salesTable.length ? (
                  <div>
                    <a-popconfirm
                      title="確定要刪除嗎?"
                      onConfirm={() =>
                        this.deleteSalesTable(
                          row,
                          (index =
                            (this.newCurrent - 1) * this.newPageSize + index)
                        )
                      }
                    >
                      <a>刪除</a>
                    </a-popconfirm>
                  </div>
                ) : (
                  <span>{row}</span>
                )}
              </div>
            )
          }),
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  created() {
    this.getAllTagList()
    this.getClassesList()
    this.getCustomerList()
  },
  watch: {
    'list.weight'(val) {
      if (parseFloat(val).toFixed(3) > 0) {
        this.rules.weightUnit[0].required = true
      } else {
        this.rules.weightUnit[0].required = false
        this.$refs.ruleForm.clearValidate()
      }
    },
    productCategories(list) {
      if (this.categoriesDialogVisible) this.categoriesData = list.map(item => ({
        id: item.id,
        name: item.name,
        edit: true,
        add: false,
        isEdited: false,
      }))
    },
    categoriesDialogVisible(visible) {
      if (visible) this.categoriesData = this.productCategories.map(item => ({
        id: item.id,
        name: item.name,
        edit: true,
        add: false,
        isEdited: false,
      }))
    },
  },
  methods: {
    barCodeVerify() {
      if (this.list.categories == 'RETAIL') {
        this.rules.barcode[0].required = true
      } else {
        this.rules.barcode[0].required = false
        this.list.barcode = ''
        this.$refs.ruleForm.clearValidate()
      }
    },
    weightUnitVerify() {
      console.log(parseFloat(this.list.weight).toFixed(3) > 0)
      this.$refs.ruleForm.clearValidate()
    },
    getCustomerList() {
      this.$api.Inventory.onlyCustomerList().then(res => {
        this.customerList = res.data
      })
    },
    handleChange(id) {
      this.customerId = id
    },
    clientOption(id) {
      this.selectedId = id
    },
    handleAdd() {
      const { salesTable } = this
      const self = this
      const newData = {
        classes: '',
        discountId: undefined,
        clientName: '',
        discountPrice: undefined,
        remark: '',
        isEditDiscountPrice: true,
        isEditRemark: true,
        get storeClient() {
          //找到已選過的選項
          let selected = self.salesTable.map(stItem => stItem.clientName)
          return self.customerList.filter(item => {
            //把找到的選項加上禁用
            item.disabled = selected.includes(item.id)
            return item.classes.id === newData.classes
          })
        }
      }
      this.salesTable = [...salesTable, newData]
    },
    salesTableChange({ current, pageSize }) {
      this.newCurrent = current
      this.newPageSize = pageSize
    },
    unitSelectChange(id) {
      this.unitName = this.unitData.find(x=>x.id==id).name
    },
    weightUnitSelectChange(id) {
      this.weightUnitName = this.unitData.find(x=>x.id==id).name
    },
    async showModal(record) {
      this.unitId = ''
      this.weightUnitId = ''
      this.visible = true
      await this.$api.Commodity.getUnitList().then(res => {
        this.unitData = res.data
      })
      setTimeout(()=>{
        document.querySelector('#modal-wrapper').addEventListener('keyup',this.handleOk,)
      },100)
      setTimeout(() => {
        this.$refs.inputDom.focus()
      }, 100)
      if (!record) {
        this.changeTitle = '新增商品'
        this.rules.barcode[0].required = false
      } else {
        this.unitId = record.unitId
        this.weightUnitId = record.weightUnitId
        this.track = record.id
        this.changeTitle = '編輯商品'
        if (record.using === true) {
          if (record !== '') {
            this.list.name = record.name
            this.list.barcode = record.barcode
            this.list.categoryId = record.category?.id
            this.unitName = record.unit
            this.weightUnitName = record.weightUnit
            this.list.listPrice = record.price
            this.list.price = record.dealPrice
            this.list.description = record.description
            this.list.updateTime = record.updateTime
            this.list.alias = record.alias
            this.list.weight = (record.fixedWeight || 0).toFixed(3)
            // this.list.weightUnit =
            //   record.weightUnit === null ? '' : record.weightUnit
            this.list.categories = record.productCategories
            this.list.tagId = record.tagId
            this.visible = true

            this.$api.Commodity.getCommodityDiscount({
              productId: this.track
            }).then(res => {
              this.salesTable = res.data.map(item => ({
                classes: item.classId,
                clientName: item.clientId,
                discountPrice: item.clientPrice,
                remark: item.remark,
                isEditDiscountPrice: true,
                isEditRemark: true,
                storeClient: this.customerList.filter(
                  list => list.classes.id === item.classId
                )
              }))
            })
            if (this.list.categories !== 'RETAIL') {
              this.rules.barcode[0].required = false
              this.list.barcode = ''
              this.$refs.ruleForm.clearValidate()
            } else {
              this.rules.barcode[0].required = true
            }
          }
        }
      }
    },
    showUnitDialog() {
      this.unitDialogVisible = true;
      this.isAddUnit = false
      this.isEdit = false
      this.getUnitList()
    },
    unitDialogCancel() {
      this.isAddUnit = false
      this.isEdit = false
      this.unitName = ''
      if(!this.visible) {
        this.unitData = []
      }
    },
    async getUnitList() {
      await this.$api.Commodity.getUnitList().then(res=>{
        this.unitData = []
        res.data.map(item=>{
          let data = {
            id: item.id,
            name: item.name,
            edit: item.edit,
            add: false,
            isEdited: false,
          }
          this.unitData.push(data)
        })
      })
    },
    addUnitRow() {
      if(!this.isAddUnit){
        this.isAddUnit = true
        let data = {
          id:'',
          name: '',
          edit: true,
          add: true,
          isEdited: false
        }
        this.unitData.unshift(data)
      }
    },
    addUnit() {
      this.$api.Commodity.addUnit({name:this.unitName}).then(res=>{
        this.isAddUnit = false
        this.getUnitList()
      }).catch(err=>{
        this.$message.error(err.response.data.message)
      })
    },
    cancelAdd() {
      this.unitData.splice(0,1)
      this.isAddUnit = false
    },
    editUnitRow(record) {
      if(!this.isEdit){
        this.isEdit = true
        this.isAddUnit = true
        this.$nextTick(()=>{
          record.isEdited = true
          this.unitName = record.name
        })
      }
    },
    editUnit(record) {
      this.$api.Commodity.editUnit(
              {
                id: record.id,
                name: this.unitName
              }
              ).then(()=>{
        this.isEdit = false
        this.isAddUnit = false
        this.getUnitList()
      }).catch(err=>{
        this.$message.error(err.response.data.message)
      })
    },
    cancelEdit(record) {
      record.isEdited = false
      this.isEdit = false
      this.isAddUnit = false
    },
    deleteUnit(record) {
      this.$api.Commodity.delUnit(record.id).then(()=>{
        this.getUnitList()
      })
    },
    submitNonstop() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.changeTitle === '新增商品') {
            this.$api.Commodity.addCommodity({
              name: this.list.name,
              unitId: this.unitId,
              categoryId: this.list.categoryId,
              weightUnitId: this.weightUnitId,
              unit: this.unitName,
              barcode: this.list.barcode,
              price: parseInt(this.list.listPrice),
              dealPrice: parseInt(this.list.price),
              description: this.list.description,
              fixedWeight: this.list.weight
                              ? parseFloat(this.list.weight).toFixed(3)
                              : 0,
              weightUnit: this.weightUnitName,
              productCategories : this.list.categories,
              tagId: this.list.tagId,
              alias: this.list.alias,
              using: true,
              discountRequestList: this.salesTable.map(item => {
                return {
                  clientId: item.clientName,
                  price: item.discountPrice,
                  remark: item.remark
                }
              })
            })
              .then(res => {
                this.getCommodity()
                this.$message.success(`新增${res.data.name}成功`)
                this.visible = true
                this.rules.barcode[0].required = false
                this.clearInput()
              })
              .catch(err => {
                this.$message.error(err.response.data.message)
                this.visible = true
                if (!this.list.tagId) {
                  this.$message.error('請先設定標籤')
                } else if (
                  this.tableData.some(item => item.name === this.list.name)
                ) {
                  this.$message.error('此商品已存在')
                }
              })
          }
        }
      })
    },
    handleOk(e) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(e.target.innerText === '儲 存' || e.key === 'Enter'){
            if (this.changeTitle === '新增商品') {
              this.$api.Commodity.addCommodity({
                name: this.list.name,
                unitId: this.unitId,
                categoryId: this.list.categoryId,
                weightUnitId: this.weightUnitId,
                unit: this.unitName,
                barcode: this.list.barcode,
                price: parseInt(this.list.listPrice),
                dealPrice: parseInt(this.list.price),
                description: this.list.description,
                fixedWeight: this.list.weight
                                ? parseFloat(this.list.weight).toFixed(3)
                                : 0,
                weightUnit: this.weightUnitName,
                productCategories : this.list.categories,
                tagId: this.list.tagId,
                alias: this.list.alias,
                using: true,
                discountRequestList: this.salesTable.map(item => {
                  return {
                    clientId: item.clientName,
                    price: item.discountPrice,
                    remark: item.remark
                  }
                })
              })
                      .then(res => {
                        this.getCommodity()
                        this.$message.success(`新增${res.data.name}成功`)
                        this.visible = false
                        this.clearInput()
                      })
                      .catch(err => {
                        this.$message.error(err.response.data.message)
                        this.visible = true
                        if (!this.list.tagId) {
                          this.$message.error('請先設定標籤')
                        } else if (
                          this.tableData.some(item => item.name === this.list.name)
                        ) {
                          this.$message.error('此商品已存在')
                        }
                      })
            } else {
              this.$api.Commodity.updateCommodity({
                id: this.track,
                name: this.list.name,
                categoryId: this.list.categoryId,
                unitId: this.unitId,
                weightUnitId: this.weightUnitId,
                unit: this.unitName,
                barcode: this.list.barcode,
                price: this.list.listPrice,
                dealPrice: this.list.price,
                description: this.list.description,
                fixedWeight: this.list.weight
                                ? parseFloat(this.list.weight).toFixed(3)
                                : 0,
                weightUnit: this.weightUnitName,
                productCategories : this.list.categories,
                tagId: this.list.tagId,
                alias: this.list.alias,
                using: true,
                discountRequestList: this.salesTable.map(item => {
                  return {
                    clientId: item.clientName,
                    price: item.discountPrice,
                    remark: item.remark
                  }
                })
              })
                      .then(() => {
                        this.getCommodity()
                        this.$message.success('修改商品成功')
                        this.clearInput()
                        this.visible = false
                      })
                      .catch(err => {
                        this.$message.error(err.response.data.message)
                        this.visible = true
                        if (!this.list.tagId) {
                          this.$message.error('請先設定標籤')
                        } else if (
                          this.tableData.some(item => item.name === this.list.name)
                        ) {
                          this.$message.error('此商品已存在')
                        }
                      })
            }
          }
        }
      })
    },
    async deleteSalesTable(row, index) {
      if (this.changeTitle === '新增商品') {
        this.salesTable.splice(index, 1)
      } else {
        if (row.id) {
          try {
            await this.$api.Customer.discountRemove(row)
            this.salesTable.splice(index, 1)
            this.$message.success('刪除折扣成功')
          } catch (err) {
            this.$message.error('刪除折扣失敗')
            console.log(err)
          }
        } else {
          this.salesTable.splice(index, 1)
        }
      }
      // if ((index % 10) + 1 === 1 && index !== 0) {
      //   this.newCurrent--
      // }
    },
    keepSelection() {
      this.salesTable.reduce((p, v) => {
        return v.classes ? { ...p, [v.classes]: true } : p
      }, {})
    },
    handleCancel() {
      this.visible = false
      this.clearInput()
    },
    clearInput() {
      this.list = {...initialList}
      this.salesTable = []
      this.resetForm()
    },
    addNewItem(row, editKey) {
      row[editKey] = false
    },
    inputORnot(row, editKey) {
      row[editKey] = true
    },
    resetForm() {
      this.$refs.ruleForm.resetFields()
    },
    getClassesList() {
      this.$api.Customer.getClass().then(res => {
        this.classesList = res.data
      })
    },
    getAllTagList() {
      this.$api.Label.getAllTag().then(res => {
        this.tagList = res.data
        this.list.tagId = this.tagList[0].id
        let oldestTime = res.data.reduce((p, c) => {
          return p.createTime > c.createTime ? c : p
        })
        this.defaultTagId = oldestTime.id
      })
    },
    passTagId(id) {
      this.list.tagId = id
      document.getElementsByClassName('aaa')[0].focus()
      this.$refs.ruleForm.clearValidate()
    },
    showCategoryDialog() {
      this.categoriesDialogVisible = true;
      this.isAddCategory = false
      this.isEdit = false
    },
    categoriesDialogCancel() {
      this.isAddCategory = false
      this.isEdit = false
      this.categoryName = ''
      if(!this.visible) {
        this.categoriesData = []
      }
    },
    addCategoryRow() {
      if(!this.isAddCategory){
        this.isAddCategory = true
        let data = {
          id: '',
          name: '',
          edit: true,
          add: true,
          isEdited: false
        }
        this.categoriesData.unshift(data)
      }
    },
    editCategoryRow(record) {
      if(!this.isEdit){
        this.isEdit = true
        this.isAddCategory = true
        this.$nextTick(()=>{
          record.isEdited = true
          this.categoryName = record.name
        })
      }
    },
    deleteCategory(record) {
      this.$api.Commodity.delCategory(record.id).then(()=>{
        this.getProductCategories()
      }).catch(err => {
        let errorMessage = err?.response?.data?.message
        if(errorMessage) {
          this.$message.error(errorMessage)
        }
      })
    },
    cancelEditCategory(record) {
      record.isEdited = false
      this.isEdit = false
      this.isAddCategory = false
    },
    editCategory(record) {
      this.$api.Commodity.editCategory(
        {
          id: record.id,
          name: this.categoryName
        }
      ).then(()=>{
        this.isEdit = false
        this.isAddCategory = false
        this.getProductCategories()
      }).catch(err=>{
        this.$message.error(err.response.data.message)
      })
    },
    cancelAddCategory() {
      this.categoriesData.splice(0,1)
      this.isAddCategory = false
    },
    addCategory() {
      this.$api.Commodity.addCategory({name:this.categoryName}).then(res=>{
        this.isAddCategory = false
        this.getProductCategories()
      }).catch(err=>{
        this.$message.error(err.response.data.message)
      })
    },
  },
  computed: {
    decimalFormat() {
      return this.list.weight
        .toString()
        .replace(
          /\.\d{2,}$/,
          this.list.weight
            .toString()
            .substr(this.list.weight.toString().indexOf('.'), 3)
        )
    },
    listPriceFormatting: {
      get: function() {
        return formatPrice(this.list.listPrice)
      },
      set: function(newValue) {
        this.list.listPrice = newValue.replace(/[^\d]/g, '')
      }
    },
    priceFormatting: {
      get: function() {
        return formatPrice(this.list.price)
      },
      set: function(newValue) {
        this.list.price = newValue.replace(/[^\d]/g, '')
      }
    },
    priceAndRemarkEditor() {
      return (val, row, key) => {
        let editKey =
          'isEdit' + key[0].toUpperCase() + key.substring(1, key.length)
        return {
          children: (
            <div class="displayInput">
              {row[editKey] ? (
                <div>
                  <a-input
                    autoFocus
                    placeholder="請輸入"
                    value={row[key]}
                    vModel={row[key]}
                    onKeyup={() => {
                        if(key === 'discountPrice') {
                              let value = row[key]
                              value = value.replace(/[^\d.]/g, ""); //清除"數字"和"."以外的字元
                              value = value.replace(/^\./g, ""); //驗證第一個字元是數字
                              value = value.replace(/\.{2,}/g, "."); //只保留第一個, 清除多餘的
                              value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                              value = value.replace(/^(\-)*(\d+)\.(\d)(\d).*$/, '$2.$3$4'); //只能輸入兩個小數
                              row[key] = value
                        }
                      }
                    }
                    vOn:Keyup_enter={() => this.addNewItem(row, editKey)}
                  />
                </div>
              ) : (
                <Fragment>
                  <span onClick={() => this.inputORnot(row, editKey)}>
                    {key === 'discountPrice' ? '$' + formatPrice(val) : val}
                  </span>
                  <div class="displayEdit" />
                  <a-icon
                    className="editable-cell-icon"
                    type="edit"
                    onClick={() => this.inputORnot(row, editKey)}
                  />
                </Fragment>
              )}
            </div>
          )
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.modal-body {
  background-color: #f5f5f5;
  /*display: flex;*/
}

.displayEdit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.editable-add-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}
.editable-cell-icon {
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  top: 3.5px;
}
.tagMessage {
  width: 100%;
  margin: -40px 0 0 355px;
  z-index: -1;
  color: red;
}
.weight-wrapper {
  display: flex;
}

.firstPart {
  padding: 30px 20px 30px 20px;
}

.firstPart-item {
  display: flex;
  /*flex-direction: column;*/
  flex-wrap: wrap;
  justify-content: space-evenly;
  &__row {
    display: flex;
    width: 100%;
    &.firstPart-item__row-full > * {
      width: 100%;
    }
    &:not(.firstPart-item__row-full) > * {
      width: 50%;
      &:nth-child(2) {
        margin-left: 24px;
      }
    }
  }
}

.flex-1-form-model-item {
  display: flex;
  flex: 1;
  &::v-deep .ant-form-item-control-wrapper {
    flex: 1;
  }
}

.custom-form-item {
  display: flex;
  margin: 0 0 8px 0;
  &-center {
    align-items: center;
  }
  &::v-deep {
    .ant-form-item-label {
      width: 88px;
      min-width: 88px;
      text-align: left;
      > label:not(.ant-form-item-required) {
        padding-left: 11px;
      }
    }
  }
}

.product-category {
  display: inline-flex;
  &__btn {
    margin-left: 8px;
    transform: translateY(3px);
  }
}

.firstPart-weight {
  display: flex;
  align-items: center;
  &__input {
    flex: 1;
  }
  &__select {
    width: 100px;
    margin-left: 8px;
  }
}

.firstPart-unit {
  display: flex;
  align-items: center;
  &__select {

  }
  &__btn {
    margin-left: 8px;
  }
}
</style>
