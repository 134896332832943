var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"action"},[_c('CustomPrice',{ref:"Modal",attrs:{"getCommodity":_vm.getCommodity,"tableData":_vm.tableData,"productCategories":_vm.productCategories,"getProductCategories":_vm.getProductCategories}}),_c('div',{staticClass:"search"},[_c('a-input-search',{attrs:{"placeholder":"搜尋商品名稱","enter-button":""},on:{"search":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"itemMenu"},[_c('a-button',{staticClass:"busSort-btn",style:({backgroundColor: this.isClick === true? '#81D3F8':'white'}),on:{"click":_vm.setBusinessSort}},[_vm._v(" 商用包 ")]),_c('a-button',{staticClass:"retailSort-btn",style:({backgroundColor: this.isClick2 === true? '#81D3F8':'white'}),on:{"click":_vm.setRetailSort}},[_vm._v(" 零售包 ")]),_c('draggable',{attrs:{"list":_vm.productCategories,"ghost-class":"ghost"},on:{"start":function($event){_vm.dragging = true},"end":_vm.endProductCategoryMove}},_vm._l((_vm.productCategories),function(e){return _c('a-button',{key:e.id,style:({backgroundColor: e.id === _vm.productCategoryId ? '#81D3F8' : undefined}),on:{"click":function($event){return _vm.handleClickProductCategory(e.id)}}},[_vm._v(" "+_vm._s(e.name)+" ")])}),1),_c('a-table',{ref:"table",staticClass:"sort-table",attrs:{"columns":_vm.columns,"data-source":_vm.tableData,"bordered":"","rowKey":"id","pagination":false,"loading":_vm.isGettingCustomer},scopedSlots:_vm._u([_vm._l(([
          'barcode',
          'name',
          'unit',
          'listPrice',
          'stockAmount',
          'createDate',
          'price',
          'description'
        ]),function(col){return {key:col,fn:function(text, record){return [_c('div',{key:col},[(col === 'unit')?[_c('div',{style:({color: record.using === true? 'black':'#ACB9BF'})},[_vm._v(" "+_vm._s(_vm.computedWeight(undefined, text))+" ")])]:[_c('div',{style:({color: record.using === true? 'black':'#ACB9BF'})},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(col === 'barcode'),expression:"col === 'barcode'"}],staticStyle:{"float":"left"}},[_c('a-icon',{attrs:{"type":"menu"}})],1),_c('span',[_vm._v(" "+_vm._s(text)+" ")])])]],2)]}}}),{key:"using",fn:function(using, record){return [_c('a-switch',{attrs:{"checked":using},on:{"change":function($event){return _vm.onChange($event, record)}}})]}},{key:"operation",fn:function(text, record){return [(record.using === true)?[_c('a-button',{attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.editHandler(record)}}},[_vm._v("編輯")])]:[_c('a-button',{attrs:{"type":"link","size":"small","disabled":""},on:{"click":function($event){return _vm.editHandler(record)}}},[_vm._v("編輯")])]]}}],null,true)})],1),_c('a-pagination',{staticClass:"pagination",attrs:{"page-size-options":_vm.pageSizeOptions,"total":_vm.total,"show-size-changer":"","page-size":_vm.pageSize,"show-total":function (total) { return ("總共 " + total + " 筆"); }},on:{"change":_vm.onPageChange,"showSizeChange":_vm.onShowSizeChange},scopedSlots:_vm._u([{key:"buildOptionText",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.value)+"筆/頁")])]}}]),model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }